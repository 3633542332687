.library-card {
  .title {
    color: #005EA2;
    font-weight: 700;
  }
}

.library-results {
  font-weight: 700;
  font-size: 22px;
}

.library-card-box {
  text-decoration: none;
  color: inherit;
}

.title-nolink {
  font-weight: 700;
  text-decoration: none;
  color: black;
}