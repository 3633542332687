.primary-nav {
  background: linear-gradient(90deg, #0057B7 0, #0081A1 100%);
}

.primary-nav-logo {
  background-color: white;
}

@media all and (min-width: 40em){
  div.primary-nav-logo {
    background-color: inherit;
  }
}

.primary-nav .bg-overlay {
  opacity: 70%;
}