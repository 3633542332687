.faq-box {
    max-width: 1000px;
    background-color: white;
    border: 1px solid #112F4E;
    padding: 0px;
    margin-bottom: 20px;
  }
  
  .faq-toggle-button {
    display: inline-block;
    width: 20px;
    text-align: center;
    margin-left: 5px;
    margin-top: 3px;
    margin-right: 5px;
    margin-bottom: 3px;
    padding-left: 1px;
    padding-top: -1px;
    padding-bottom: 2px;
    font-size: 18px;
    border: 1px solid #112F4E;
  
  }
  
  .faq-question {
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 1px;
    margin: 0;
    border-bottom: 1px solid #112F4E;
  }
  
  .faq-answer {
    padding: 10px;
    margin: 0px;
  }
  
  .faq {
    flex: 1;
  }
  
  @media (min-width: 768px) {
    .faq {
      flex: 2 0 66.66%;
    }
  }
  
  @media (max-width: 768px) {
    .faq {
      padding-left: 25px;
    }
  }