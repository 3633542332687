@import url('@uswds/uswds/css/uswds.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@media all and (min-width: 64em) {
  .desktop\:text-left {
    text-align: left;
  }
}

@media all and (min-width: 40em) {
  .tablet\:flex-row {
    flex-direction: row !important;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
}

h1, h2 {
  color: #0B4778;
  font-weight: 400;
}

a {
  color: #005EA2;

}

.font-family-poppins {
  font-family: 'Poppins', sans-serif;
}

.text-dark-blue {
  color: #0B4778;
}

.text-light-blue {
  color: #005EA2;

}

.nav-bg-dark-blue {
  background-color: #0B4778;
}

.bg-light-blue {
  background-color: #E8F5FF;

}

.nav-bg-dark-blue-hover:hover {
  background-color: #0B4778;
  color: #f0f0f0;
  cursor: pointer;
  text-decoration: none;
  border-top: 2px solid white;
}

.dgw-text-yellow {
  color: #FFAD42;
}

.usa-button--accent-warm {
  background-color: #FFAD42;
}

.usa-button--accent-warm:visited {
  background-color: #FFAD42;
}

.homepage-icon {
  height: 80px;
}

.service-now-question {
  font-size: 16px;
  font-weight: 600;
}

.service-now-description {
  padding-bottom: 24px;
  max-width: 30rem;

  &.support {
    padding-bottom: 0px;
  }
}

.service-now-asterisk {
  color: #D54309;
  white-space: nowrap;
}

.service-now-question-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &.single {
    width: 100%;
  }

  &.support {
    justify-content: flex-end;
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}

.service-now-ticket-description {
  font-size: 20px;
}

.single-item {
  max-width: 100%
}

.page-title {
  font-weight: 300;
}

.light-blue-background {
  background-color: #E8F5FF;
}

.custom-h3 {
  font-size: 22px;
  font-weight: 700;
  color: #0f0f0f;
}