.socials-circle {
  border-radius: 100%;
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 14px;
}

.socials-circle a {
  padding: 3px;
  display: inline-block;
}

.footer-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-links li {
  margin-bottom: 10px;
  margin-right: 10px; 
}

.footer-links li:last-child {
  margin-right: 0; /* Removes right margin from the last item in the row */
}

@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    align-items: flex-start;
  }
}