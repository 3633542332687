.markdown {
  h1, h2, h3 {
    color:#0B4778
  }
    
  .usa-table tr:nth-child(odd) td {
    background-color: #E8F5FF;
  }
  
  .usa-table tr:nth-child(1) th {
    background-color: #005EA2;
    color:#FFFFFF
  }
  
  .usa-table tr:nth-child(1) td {
    &.center-cell{
        background-color: #005EA2;
        color:#FFFFFF
    }
  }
  
  .center-cell {
    text-align: center;
  }
  
  pre {
    border: #112F4E;
    border-width: 1px;
    border-style: solid;
    width:fit-content;
    white-space: pre-wrap; /* Enables wrapping of long lines */
    word-wrap: break-word; /* Prevents breaking the container */
    overflow-x: auto; /* Adds horizontal scrolling if content overflows */
    max-width: 100%; /* Ensures the pre element doesn't exceed the container's width */
    box-sizing: border-box; /* Includes padding and border in the element's total width */  
  }

  code {
    font-family: Consolas, Monaco, Courier New, Courier, monospace;
    font-size: 13px;
    display: block;
  }
}

